import Cookies from 'js-cookie';
import ApiManager from './networking/ApiManager';
import PopupStyles from './styles/popup';
import {
    Survey,
    SurveyThemePopupRoundedCorners,
    QualliSDKInitOptions,
    Person,
    Trigger,
} from './types/index';
import {SurveyEvents} from './types/events';
import mapPopupBorderRadiusToPx from './helpers/getRoundedCornersInPx';
import {urls} from './config';
import {eventEmitter} from './utils/EventEmitter';

class QualliSDK {
    company: any = {};
    ready = false;
    _popupId = 'qualli-survey-popup-iframe';
    _personId = 'qualli-survey-person';
    _userSessionKey = '';
    _userKey = '';
    _apiKey = '';
    _surveyToComplete?: Survey;
    _popupInFrame = false;
    _previousUrl = '';
    _triggerQueue: {key?: string; uniqueId?: string}[] = [];
    _attributesQueue: {[key: string]: string | number}[] = [];
    _activeTriggers: Trigger[] = [];
    _trackScreens = false;
    _canTrackScrollDepthAgain = true;

    constructor() {
        this._handleIframeMessages = this._handleIframeMessages.bind(this);
    }

    async init(apiKey: string, options?: QualliSDKInitOptions) {
        this._apiKey = apiKey;

        await this.identify();
        this.ready = true;

        if (options?.trackScreens || !options) {
            this._trackScreens = true;

            this._trackScreenChange();
        }

        this._setupScreenTracking();

        this._checkQueueAfterInit();
    }

    async identify() {
        // check out cookies for a use session key
        // if no key, create one
        // save the key in cookies
        let userKey = Cookies.get('qualli_user_key');
        let userSessionKey = Cookies.get('qualli_user_session_key');

        const response = await ApiManager.identify(
            this._apiKey,
            userKey,
            userSessionKey,
        );
        if (
            !response?.success ||
            !response?.app_user_key ||
            !response?.session_key
        ) {
            return;
        }

        Cookies.set('qualli_user_session_key', response.session_key);
        Cookies.set('qualli_user_key', response.app_user_key);

        this._userSessionKey = response.session_key;
        this._userKey = response.app_user_key;
        this.company = response.company_info;
        this._activeTriggers = response.triggers;

        this._setupActiveTriggerTracking();
    }

    async _checkQueueAfterInit() {
        if (this._attributesQueue.length > 0) {
            await Promise.all(
                this._attributesQueue.map(async (attributes) => {
                    await this.setAttributes(attributes);
                }),
            );
        }

        // see if we have any triggers to perform
        if (this._triggerQueue.length > 0) {
            this._triggerQueue.forEach(async (trigger) => {
                if (trigger.key) {
                    await this._performTrigger(trigger.key, null);
                } else if (trigger.uniqueId) {
                    await this._performTrigger(null, trigger.uniqueId);
                }
            });
        }
    }

    async performTrigger(trigger: string) {
        this._performTrigger(trigger, null);
    }

    async setAttributes(attributes: {[key: string]: string | number} = {}) {
        // this should only be called if the init isn't done yet
        if (!this.ready) {
            this._attributesQueue.push(attributes);
            return;
        }

        await ApiManager.setUserAttributes(
            this._apiKey,
            this._userSessionKey,
            attributes,
        );
    }

    reset() {
        if (this._popupInFrame) {
            this._closePopup();
        }

        // clear cookies
        Cookies.remove('qualli_user_session_key');
        Cookies.remove('qualli_user_key');

        this.ready = false;
        this._userSessionKey = '';
        this._userKey = '';
        this._surveyToComplete = undefined;
        this._popupInFrame = false;
        this._previousUrl = '';

        // re-init
        setTimeout(() => {
            this.init(this._apiKey);
        }, 1 * 1000);
    }

    on(event: SurveyEvents, listener: Function): () => void {
        eventEmitter.on(event, listener);

        // Return an unsubscribe function
        return () => {
            eventEmitter.off(event, listener);
        };
    }

    _setupScreenTracking() {
        const originalPushState = history.pushState;
        const originalReplaceState = history.replaceState;

        history.pushState = (...args) => {
            originalPushState.apply(history, args);
            this._trackScreenChange();
        };

        history.replaceState = (...args) => {
            originalReplaceState.apply(history, args);
            this._trackScreenChange();
        };

        window.addEventListener('popstate', () => {
            this._trackScreenChange();
        });
    }

    // key is the unique_key created by the user
    // uniqueId is the unique_identifier created by Qualli
    async _performTrigger(key: string | null, uniqueId: string | null) {
        // this should only be called if the init isn't done yet
        if (!this.ready) {
            if (key) {
                this._triggerQueue.push({key});
            } else if (uniqueId) {
                this._triggerQueue.push({uniqueId});
            }
            return;
        }

        let res: any;
        if (key) {
            res = await ApiManager.performTrigger(
                this._apiKey,
                this._userSessionKey,
                {name: key},
                window.location.href,
            );
        } else if (uniqueId) {
            res = await ApiManager.performTrigger(
                this._apiKey,
                this._userSessionKey,
                {unique_id: uniqueId},
                window.location.href,
            );
        }

        // if success -> see if we have any open surveys
        if (res?.success) {
            // see if we have any open surveys
            const surveys = res?.data?.surveys?.data;

            if (surveys?.length > 0) {
                if (!this._popupInFrame) {
                    // check if the survey has a delay set (in seconds)
                    // the data will contain the timestamp of the last time the survey was shown in this format "2024-01-23T16:50:13.830Z"
                    // if the timestamp is more than the delay, show the survey
                    // if not -> queue the survey to be shown when the delay is over

                    const delay = surveys[0]?.delay;

                    if (delay) {
                        this._handleSurveyDelay(
                            surveys[0],
                            res?.data?.timestamp as string,
                        );
                        return;
                    }

                    this._showPopup(surveys[0]);
                    return;
                }
            }
        }
    }

    async _trackScreenChange() {
        // every new page the scroll depth should be tracked again
        this._canTrackScrollDepthAgain = true;

        if (!this._trackScreens) return;

        const fulllURL = window.location.href;
        // remove query params
        const path = fulllURL.split('?')[0];

        if (path === this._previousUrl) return;
        this._previousUrl = path;

        const res = await ApiManager.trackScreen(
            this._apiKey,
            this._userSessionKey,
            {url: path},
        );

        // if success -> see if we have any open surveys
        if (res?.success) {
            // see if we have any open surveys
            const surveys = res?.data?.surveys?.data;

            if (surveys?.length > 0) {
                if (!this._popupInFrame) {
                    const delay = surveys[0]?.delay;

                    if (delay) {
                        this._handleSurveyDelay(
                            surveys[0],
                            res?.data?.timestamp as string,
                        );
                        return;
                    }

                    this._showPopup(surveys[0]);
                    return;
                }
            }
        }
        // Implement your tracking logic here, e.g., send data to a server
    }

    _showPopup(survey: Survey) {
        this._surveyToComplete = survey;
        this._popupInFrame = true;
        const islarge = window.innerWidth > 600;

        const iframe = document.createElement('iframe');
        const queryParams = {
            survey_id: survey.unique_identifier,
            popup: 'true',
        };
        const queryParmasString = Object.keys(queryParams)
            // @ts-ignore-next-line
            .map((key) => key + '=' + queryParams[key])
            .join('&');

        const placement = survey?.popup_placement || 'bottom_right';
        const borderRadius = mapPopupBorderRadiusToPx(
            survey.theme.popup_rounded_corners ||
                SurveyThemePopupRoundedCorners.medium,
        );
        const borderColor = '#000000';
        const borderWidth = survey.theme.popup_border_width + 'px' || 0 + 'px';

        // Check screen size and apply styles
        let popupPlacementHorizontalStyles: any =
            PopupStyles.styles.popupPlacement.left;
        if (placement === 'bottom_right') {
            popupPlacementHorizontalStyles =
                PopupStyles.styles.popupPlacement.right;
        } else if (placement === 'bottom_center') {
            popupPlacementHorizontalStyles =
                PopupStyles.styles.popupPlacement.center;
        }

        const popupStyles = {};

        if (!islarge) {
            Object.assign(popupStyles, {
                ...PopupStyles.styles.screens.small,
            });
        } else {
            Object.assign(popupStyles, {
                ...PopupStyles.styles.screens.large,
                ...popupPlacementHorizontalStyles,
            });
        }

        Object.assign(popupStyles, {
            borderRadius: borderRadius,
            borderColor: borderColor,
            borderWidth: borderWidth,
            backgroundColor: survey.theme.background_color,
            border: 'solid',
            zIndex: 999,
        });

        Object.assign(iframe.style, popupStyles);
        iframe.src = `${urls.popupIframe}?${queryParmasString}`;
        iframe.id = this._popupId; // An identifier for the iframe

        // Append the iframe to the body
        document.body.appendChild(iframe);

        const person = survey?.person;

        if (!!person && person.video_url) {
            const show_survey = () => {
                setTimeout(
                    () => {
                        if (person.style.type === 'circle') {
                            iframe.style.bottom = '100px';
                        } else {
                            iframe.style.bottom = '115px';
                        }
                    },
                    person.logic === '2-step' ? 0 : 800,
                );
            };

            this._addPerson(person, show_survey, islarge, survey);
        } else {
            // start the animation immediately
            // Start the animation
            setTimeout(() => {
                if (window.innerWidth < 600) {
                    iframe.style.bottom = '16px';
                } else {
                    iframe.style.bottom = '24px';
                }
            }, 100);
        }

        // Event listener for messages from the iframe
        window.addEventListener('message', this._handleIframeMessages, false);
        this._emitSurvetShown(survey);
    }

    _emitSurvetShown(survey: Survey) {
        eventEmitter.emit(SurveyEvents.SURVEY_SHOWN, {
            survey_unique_identifier: this._surveyToComplete?.unique_identifier,
        });
    }

    _handleSurveyDelay(survey: Survey, timestamp: string) {
        // block from loading new surveys
        this._surveyToComplete = survey;
        this._popupInFrame = true;

        const delayInSeconds = survey.delay;
        // calculate the time with delay
        const delayedTime = new Date(timestamp);
        delayedTime.setSeconds(delayedTime.getSeconds() + delayInSeconds);

        // run a timer to check if the delay is over
        const interval = setInterval(() => {
            const now = new Date();
            if (now >= delayedTime) {
                // delay is over -> show the survey
                this._showPopup(survey);
                clearInterval(interval);
            }
        }, 200);
    }

    _handleIframeMessages(event: any) {
        // Ensure the message is from the expected source

        if (!event?.data?.message) return;

        const message = event.data.message;

        if (message.type === 'close') {
            this._closePopup();
        }

        if (message.type === 'ready') {
            // send the survey data
            this._postMessage('survey_to_complete', this._surveyToComplete);
            this._postMessage('auth', {
                userSessionKey: this._userSessionKey,
                apiKey: this._apiKey,
                companyPlan: this.company?.plan,
            });
        }

        if (message.type === 'survey_completed') {
            // this._closePopup();

            eventEmitter.emit(SurveyEvents.SURVEY_COMPLETED, {
                survey_unique_identifier: this._surveyToComplete
                    ?.unique_identifier,
                answers: message.data.answers,
            });
        }
        if (message.type === 'survey_aborted') {
            this._closePopup();
        }

        if (message.type === 'window_height_request') {
            const height = message.data.height;
            this._resizePopup(height);
        }
    }

    _resizePopup(height: number) {
        if (!isNaN(height)) {
            const iframe = document.getElementById(this._popupId);

            let totalHeight = height;

            // if the popup has a border set, we should detract this from the total height
            const borderWidth = iframe?.style?.borderWidth; // this is given as '2px' -> we need to remove the 'px' and parse it to an int
            // create a regex checking if it is the correct format
            const regex = new RegExp('^\\d+(px)?$');
            if (borderWidth && regex.test(borderWidth)) {
                totalHeight -= parseInt(borderWidth);
            }

            if (iframe) {
                iframe.style.height = totalHeight + 'px';
            }
        }
    }

    _closePopup() {
        this._popupInFrame = false;
        window.removeEventListener('message', this._handleIframeMessages);
        eventEmitter.emit(SurveyEvents.SURVEY_CLOSED, {
            survey_unique_identifier: this._surveyToComplete?.unique_identifier,
        });

        const iframe = document.getElementById(this._popupId);
        const person = document.getElementById(this._personId);

        if (iframe) {
            iframe.style.bottom = '-100%';
            iframe.style.opacity = '0';

            setTimeout(() => {
                iframe.remove();
            }, 1 * 1000); // wait long enough for the API calls to finish
        }

        if (person) {
            person.style.opacity = '0';
            person.remove();
        }
    }

    _postMessage = (message: any, data?: any) => {
        const iframe = document.getElementById(this._popupId);
        if (iframe) {
            // @ts-ignore
            iframe.contentWindow.postMessage(
                {
                    message: {
                        type: message,
                        data,
                    },
                },
                '*',
            );
        }
    };

    _addPerson(
        person: Person,
        showSurvey: () => void,
        isLarge: boolean,
        survey: Survey,
    ) {
        const personContainer = document.createElement('div');
        const personVideo = document.createElement('video');
        personVideo.src = person.video_url; // Assuming 'person' now has a 'video' property
        personVideo.loop = true;
        personVideo.muted = true;
        personVideo.autoplay = true; // Ensure autoplay is enabled
        personVideo.playsInline = true; // Ensure the video plays inline on mobile
        personContainer.id = this._personId;

        const personPositioningStyles = {};
        const personTypeStyles = {};
        const personBaseStyles = {};

        // Positioning styles
        let screenPositioningStyles = PopupStyles.styles.person.position.large;
        if (!isLarge) {
            screenPositioningStyles = PopupStyles.styles.person.position.small;
        }

        switch (survey.popup_placement || 'bottom_right') {
            case 'bottom_left':
                Object.assign(personPositioningStyles, {
                    ...screenPositioningStyles.bottom_left,
                });
                break;
            case 'bottom_center':
                Object.assign(personPositioningStyles, {
                    ...screenPositioningStyles.bottom_center,
                });
                break;
            case 'bottom_right':
                Object.assign(personPositioningStyles, {
                    ...screenPositioningStyles.bottom_right,
                });
                break;
        }

        // Type styles
        if (person.style.type === 'circle') {
            Object.assign(personTypeStyles, {
                ...PopupStyles.styles.person.type.circle.large,
            });
        } else {
            Object.assign(personTypeStyles, {
                ...PopupStyles.styles.person.type.rectangle.large,
            });
        }

        // Base styles
        Object.assign(personBaseStyles, {
            ...PopupStyles.styles.person.base.large,
        });

        // Apply combined styles to the container
        Object.assign(personContainer.style, {
            ...personBaseStyles,
            ...personTypeStyles,
            ...personPositioningStyles,
            border: `solid ${person.style.border_width}px ${person.style.border_color}`,
        });

        // Video styles to cover the container
        personVideo.style.width = '100%';
        personVideo.style.height = '100%';
        personVideo.style.objectFit = 'cover';

        // Logic for showing the survey
        if (person.logic === '2-step') {
            personContainer.onclick = showSurvey;
        }

        // Append the video to the container and the container to the body
        document.body.appendChild(personContainer);
        personContainer.appendChild(personVideo);

        // Fade in the container
        setTimeout(() => {
            personContainer.style.opacity = '1';

            if (person.logic === '1-step') {
                showSurvey();
            }
        }, 400);
    }

    _setupActiveTriggerTracking() {
        const throttle = (func: any, limit: any) => {
            let inThrottle: any;
            return function () {
                const args = arguments;
                // @ts-ignore
                const context = this;
                if (!inThrottle) {
                    func.apply(context, args);
                    inThrottle = true;
                    setTimeout(() => (inThrottle = false), limit);
                }
            };
        };

        const checkScrollDepth = throttle(() => {
            if (!this._canTrackScrollDepthAgain) return;

            const scrollDepth =
                (window.scrollY /
                    (document.documentElement.scrollHeight -
                        window.innerHeight)) *
                100;

            this._activeTriggers.forEach((trigger) => {
                if (
                    trigger.scroll_depth &&
                    scrollDepth >= trigger.scroll_depth
                ) {
                    this._canTrackScrollDepthAgain = false;
                    this._performTrigger(null, trigger.unique_identifier);
                }
            });
        }, 200);

        if (this._activeTriggers?.some((t) => t.scroll_depth !== null)) {
            window.addEventListener('scroll', checkScrollDepth);
        }

        const checkElementInteraction = (event: any) => {
            this._activeTriggers?.forEach((trigger) => {
                // Handle non-scroll triggers
                const targetElement = event.target;
                if (
                    trigger.css_selector &&
                    targetElement.matches(trigger.css_selector)
                ) {
                    this._performTrigger(null, trigger.unique_identifier);
                } else if (
                    trigger.inner_text &&
                    targetElement.innerText === trigger.inner_text
                ) {
                    this._performTrigger(null, trigger.unique_identifier);
                }
            });
        };

        if (
            this._activeTriggers?.some(
                (t) => t.unique_key || t.css_selector || t.inner_text,
            )
        ) {
            document.body.addEventListener('click', checkElementInteraction);
        }
    }
}

// Initialize and export the SDK instance
const Qualli = new QualliSDK();
export default Qualli;
