import {SurveyThemePopupRoundedCorners} from '../types/index';

const mapPopupBorderRadiusToPx = (
    borderRadius: SurveyThemePopupRoundedCorners,
): number => {
    switch (borderRadius) {
        case SurveyThemePopupRoundedCorners.none:
            return 0;
        case SurveyThemePopupRoundedCorners.small:
            return 10;
        case SurveyThemePopupRoundedCorners.medium:
            return 18;
        case SurveyThemePopupRoundedCorners.large:
            return 24;
        default:
            return 10; // default
    }
};

export default mapPopupBorderRadiusToPx;
