// large screen styles
const largeScreenStyles = {
    position: 'fixed',
    width: '350px',
    height: '250px',
    bottom: '-100%',
    'border-radius': '12px',
    border: 'none',
    opacity: 1,
    transition: 'all 400ms ease-in-out',
};

const largeScreenLeftStyles = {
    left: '16px',
};

const largeScreenCentertStyles = {
    left: '50%',
    transform: 'translate(-50%, 0)',
};

const largeScreenRightStyles = {
    right: '16px',
};

// Styles for smaller screens
const smallScreenStyles = {
    position: 'fixed',
    left: '16px',
    right: '16px',
    bottom: '-100%', // start off screen
    width: 'calc(100% - 32px)',
    height: '200px',
    'border-radius': '12px',
    border: 'none',
    'max-height': '100%',
    transition: 'all 400ms ease-in-out',
};

export default {
    styles: {
        screens: {
            large: largeScreenStyles,
            small: smallScreenStyles,
        },
        popupPlacement: {
            left: largeScreenLeftStyles,
            center: largeScreenCentertStyles,
            right: largeScreenRightStyles,
        },
        person: {
            base: {
                small: {
                    position: 'fixed',
                    opacity: 0,
                    transition: 'all 400ms ease-in-out',
                    cursor: 'pointer',
                    bottom: '16px',
                    background: 'none',
                    overflow: 'hidden',
                },
                large: {
                    position: 'fixed',
                    opacity: 0,
                    transition: 'all 400ms ease-in-out',
                    cursor: 'pointer',
                    bottom: '16px',
                    background: 'none',
                    overflow: 'hidden',
                },
            },
            type: {
                circle: {
                    small: {
                        width: '60px',
                        height: '60px',
                        'border-radius': '50%',
                    },
                    large: {
                        width: '75px',
                        height: '75px',
                        'border-radius': '50%',
                    },
                },
                rectangle: {
                    small: {
                        width: '60px',
                        height: '90px',
                        'border-radius': '6px',
                    },
                    large: {
                        width: '60px',
                        height: '90px',
                        'border-radius': '6px',
                    },
                },
            },
            position: {
                small: {
                    bottom_left: {
                        left: '16px',
                    },
                    bottom_center: {
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                    },
                    bottom_right: {
                        right: '16px',
                    },
                },
                large: {
                    bottom_left: {
                        left: '16px',
                    },
                    bottom_center: {
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                    },
                    bottom_right: {
                        right: '16px',
                    },
                },
            },
        },
    },
};
